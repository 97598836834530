.App {
  text-align: center;
}

.bolded{font-weight: bold;}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.flex-container {
  display: flex;
  max-width: 800px;
  margin: 0 auto;
}

.flex-child {
  flex: 1;
  border: 2px solid #eee;
  padding: 10px;
  padding-bottom: 50px;
  margin-top: 20px;
  padding-top: 0px;
}

.flex-child:first-child {
  margin-right: 20px;
}

.flex-child:nth-child(2) {
  margin-right: 20px;
}

* {
  font-size: 18px;
}
h3 {
  font-size: 24px;
}
input {
  padding: 3px;
  width: 150px;
}
select {
  padding: 3px;
}

h1 {
  font-size: 30px;
}
.to_sec {
  min-width: 5em;
  display: inline-block;
}

button {
  font-size: 40%;
  /* remove all style and format as link*/
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  font-size: inherit;
  color: rgb(167, 167, 167);
  text-decoration: underline;
}

.margined{
  margin-top:50px;
}